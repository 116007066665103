import {
  SET_LOADING_MUTATION,
  SET_LOCAL_LOADING_MUTATION,
  SHOW_INFORMATION_TOAST_ACTION,
} from '@/Modules/Base/BaseModule.js'
import store from '@/Setup/SetupStore.js'
import axios from 'axios'
import { i18n } from '@/Setup/SetupI18n.js'

axios.interceptors.request.use((config) => {
  if (!config.excludeLoader) {
    store.commit('BaseModule/' + SET_LOADING_MUTATION, true)
  }

  store.commit('BaseModule/' + SET_LOCAL_LOADING_MUTATION, true)

  if (config.authorization === null) {
    config.headers.Authorization = null

    return config
  }

  if (localStorage.getItem('auth_token')) {
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      'auth_token'
    )}`
  }

  return config
})

let isRefreshing = false
let subscribers = []

axios.interceptors.response.use(
  (response) => {
    if (!response?.config.excludeLoader) {
      store.commit('BaseModule/' + SET_LOADING_MUTATION, false)
    }
    store.commit('BaseModule/' + SET_LOCAL_LOADING_MUTATION, false)

    return response
  },
  async (error) => {
    if (axios.isCancel(error)) return Promise.reject(error)
    if (!error.response?.config.excludeLoader) {
      store.commit('BaseModule/' + SET_LOADING_MUTATION, false)
    }
    store.commit('BaseModule/' + SET_LOCAL_LOADING_MUTATION, false)

    const token = localStorage.getItem('auth_token')
    const originalRequest = error.config

    if (
      error.response &&
      error.response.status === 403 &&
      error.response.headers['x-redirect-url']
    ) {
      let redirectUrl = error.response.headers['x-redirect-url']
      if (!/^https?:\/\//i.test(redirectUrl)) {
        redirectUrl = 'https://' + redirectUrl
      }
      window.location.href = redirectUrl
    }

    let shouldRefresh =
      token !== null &&
      error.response &&
      (error.response.status === 401 || error.response.status === 402) &&
      !originalRequest._retry

    if (originalRequest.url.includes('api/refresh')) {
      shouldRefresh = false
    }

    if (originalRequest.url.includes('api-integrations')) {
      shouldRefresh = false
    }

    let forceRefreshOnError = true
    if (error.response?.config.hasOwnProperty('forceRefreshOnError'))
      forceRefreshOnError = error.response?.config.forceRefreshOnError

    if (shouldRefresh && forceRefreshOnError) {
      originalRequest._retry = true

      if (!isRefreshing) {
        isRefreshing = true

        const refreshRequestConfig = { ...originalRequest }
        refreshRequestConfig.authorization = null

        axios
          .post('/refresh', { token }, { ...refreshRequestConfig })
          .then((response) => {
            localStorage.setItem('auth_token', response.data.access_token)
            isRefreshing = false
            onTokenRefreshed({ token: response.data.access_token })
          })
          .catch((refreshError) => {
            if ([500, 410].includes(refreshError.response.status)) {
              isRefreshing = false
              localStorage.removeItem('auth_token')
              location.reload()
            }
          })
      }

      // Subscribe to token refresh
      return new Promise((resolve) => {
        subscribeTokenRefresh(({ token }) => {
          if (
            originalRequest.headers.Authorization &&
            originalRequest.headers.Authorization.indexOf('Bearer') > -1
          ) {
            originalRequest.headers.Authorization = `Bearer ${token}`
          }

          return resolve(axios.request(originalRequest))
        })
      })
    }

    if (error.response) {
      switch (error.response.status) {
        case 404:
          await store.dispatch(`BaseModule/${SHOW_INFORMATION_TOAST_ACTION}`, {
            title: 'Error!',
            text: i18n.tc('error.404'),
            variant: 'warning',
          })
          break
        case 400:
          let data = error.response?.data

          if (data instanceof ArrayBuffer) {
            const blob = new Blob([data])
            await blob.text().then((text) => {
              try {
                data = JSON.parse(text)
              } catch (error) {
                console.log(error)
              }
            })
          }

          await store.dispatch(`BaseModule/${SHOW_INFORMATION_TOAST_ACTION}`, {
            title: 'Error!',
            text: data?.message || i18n.tc('error.400'),
            variant: 'danger',
          })
          break
        case 403:
          await store.dispatch(`BaseModule/${SHOW_INFORMATION_TOAST_ACTION}`, {
            title: 'Error!',
            text: error.response.data.message || i18n.tc('error.403'),
            variant: 'danger',
          })
          break
        case 429:
          await store.dispatch(`BaseModule/${SHOW_INFORMATION_TOAST_ACTION}`, {
            title: 'Error!',
            text: i18n.tc('error.429'),
            variant: 'danger',
          })
          break
        case 500:
          await store.dispatch(`BaseModule/${SHOW_INFORMATION_TOAST_ACTION}`, {
            title: 'Error!',
            text: i18n.tc('error.500'),
            variant: 'danger',
          })
      }
    }

    return Promise.reject(error)
  }
)

function subscribeTokenRefresh(call) {
  // @ts-ignore
  subscribers.push(call)
}

function onTokenRefreshed({ token }) {
  subscribers.map((call) => call({ token }))
}
